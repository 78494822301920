import { useEffect } from "react";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { loadDelivery } from "../../../../ducks/data/deliveries";
import {
  selectDelivery,
  selectGrossMargin,
} from "../../../../ducks/data/deliveries/selectors";
import { loadProducts } from "../../../../ducks/data/products";
import { selectProducts } from "../../../../ducks/data/products/selectors";
import { useTranslation } from "react-i18next";
import ExpensesNew from "./ExpensesNew";
import Invoices from "./InvoicesNew";
import "./CreateEditShipmentFinancesCard.scss";
import { useL10n } from "../../../../l10n";
import { useFeature } from "../../../../hooks";
import ShipmentDrivingReport from "../shipment-driving-report/ShipmentDrivingReport";

type CreateEditShipmentFinancesCardProps = {
  cargoId: string;
  deliveryId: string;
  clientId?: string;
  clientContact: string | null;
  clientContactName: string | null;
  invoiceRef: string | null;
  onInvoiceSent: () => void;
};

const CreateEditShipmentFinancesCard = (
  props: CreateEditShipmentFinancesCardProps
) => {
  const {
    deliveryId,
    invoiceRef,
    cargoId,
    clientContact,
    clientContactName,
    clientId,
    onInvoiceSent,
  } = props;

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadDelivery(deliveryId));
    dispatch(loadProducts());
  }, [dispatch, deliveryId]);
  const { t } = useTranslation("shipmentModal");
  const l10n = useL10n();
  const delivery = useSelector(selectDelivery(deliveryId));
  const products = useSelector(selectProducts);
  const grossMargin = useSelector(selectGrossMargin(deliveryId));
  const renderExpenses = !useFeature("hide-expenses");
  const canRenderFinanceCard = delivery && products;

  let formattedGrossMargin = "";
  if (grossMargin) {
    formattedGrossMargin = l10n.formatNumber(grossMargin.toDecimalPlaces(2));
    if (!formattedGrossMargin.includes(",")) {
      formattedGrossMargin += ",00";
    } else if (formattedGrossMargin.at(-2) === ",") {
      formattedGrossMargin += "0";
    }
  }

  return canRenderFinanceCard ? (
    <div className="shipment-dialog-finances__wrapper">
      <div className="shipment-dialog-finances__subtitle">
        {t("cards.finances.incomeTitle")}
      </div>
      <Invoices
        cargoId={cargoId}
        deliveryId={deliveryId}
        clientId={clientId}
        clientContact={clientContact}
        clientContactName={clientContactName}
        invoiceRef={invoiceRef}
        onInvoiceSent={onInvoiceSent}
      />
      <div className="mt-12 mb-6">
        <ShipmentDrivingReport cargoId={cargoId} clientId={clientId || null} />
      </div>
      {renderExpenses && (
        <>
          <div className="shipment-dialog-finances__subtitle">
            {t("cards.finances.expensesTitle")}
          </div>
          <ExpensesNew deliveryId={deliveryId} />
          <br />
          {grossMargin && (
            <div className="shipment-dialog-finances__gross-margin-wrapper">
              <div className="shipment-dialog-finances__gross-margin">
                <div>{t("cards.finances.grossMargin")}</div>
                <div>{formattedGrossMargin}</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default CreateEditShipmentFinancesCard;
