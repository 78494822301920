import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NewPlaceSearch, {
  AddressChange,
} from "../../modals/add-edit-cargo/NewPlaceSearch";
import {
  addWaypoint,
  editWaypoint,
  deleteWaypoint,
} from "../../../ducks/routes";
import { useAppDispatch } from "../../../redux-store";
import { useState } from "react";
import GenericPromptDialog from "../../modals/GenericPromptDialog/GenericPromptDialog";
import { WaypointData } from "../../../ducks/app/route-info/stops/types";
import { UFDoraCheckbox } from "../../Toolkit/DoraCheckbox";
import { useFeature } from "../../../hooks";

const validationSchema = yup.object({
  note: yup.string().optional(),
  place: yup
    .object({
      placeName: yup.string().min(1).required(),
      address: yup.string().optional(),
      city: yup.string().optional(),
      coord: yup.object({
        lon: yup.number().optional(),
        lat: yup.number().optional(),
      }),
      country: yup.string().optional(),
    })
    .required(),
  shouldShowToDriver: yup.boolean().required(),
});

const CreateEditWaypointDialog = ({
  routeId,
  data,
  onClose,
}: {
  routeId: string;
  data: WaypointData | "NEW";
  onClose: (opts?: { shouldRefresh: boolean }) => void;
}) => {
  const [shouldShowDeleteConfirmDialog, setShouldShowDeleteConfirmDialog] =
    useState(false);
  const { t } = useTranslation("dispatching");
  const { t: t2 } = useTranslation("modals");
  const liteVersion = useFeature("lite-version");
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState, getValues, setValue } =
    useForm<WaypointData>({
      resolver: yupResolver(validationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues:
        data === "NEW"
          ? {
              place: {
                placeName: "",
                address: "",
                city: "",
                coord: { lon: 0, lat: 0 },
                country: "",
              },
              note: "",
              shouldShowToDriver: true,
            }
          : {
              ...data,
              note: data.note || "",
            },
    });
  const { isValid, isDirty, isSubmitting } = formState;

  const onSubmit = async () => {
    if (data === "NEW") {
      await dispatch(addWaypoint({ routeId, waypoint: getValues() }));
    } else {
      await dispatch(editWaypoint({ routeId, waypoint: getValues() }));
    }
    onClose({ shouldRefresh: true });
  };

  const onConfirmDeleteClick = async () => {
    await dispatch(
      deleteWaypoint({ routeId, waypointId: (data as WaypointData).id! })
    );
    onClose({ shouldRefresh: true });
  };

  const onPlaceSelected = (place: null | AddressChange) => {
    setValue(
      "place",
      {
        placeName: (place && place.placeName) || "",
        address: (place && place.address) || "",
        city: (place && place.city) || "",
        coord: place && place.coord,
        country: (place && place.country) || "",
        postcode: (place && place.postcode) || "",
        googlePlaceId: place?.googlePlaceId,
      },
      { shouldValidate: true, shouldDirty: true }
    );
  };

  return (
    <Dialog
      open
      onClose={() => onClose()}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: "500px", // Set your width here
          },
        },
      }}
    >
      {shouldShowDeleteConfirmDialog && (
        <GenericPromptDialog
          title={t("waypointDialog.confirmDeleteTitle")}
          text={t("waypointDialog.confirmDeleteText")}
          onContinueClick={onConfirmDeleteClick}
          onCancelClick={() => setShouldShowDeleteConfirmDialog(false)}
          continueButtonColor="error"
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {data === "NEW"
            ? t("waypointDialog.createWaypoint")
            : t("waypointDialog.editWaypoint")}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} columnSpacing={1}>
            <Grid item xs={12}>
              <NewPlaceSearch
                onPlaceSelected={onPlaceSelected}
                placeError={false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                label={t2("addNewCargo.stepOne.inputFields.address")}
                {...register("place.address")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                label={t2("addNewCargo.stepOne.inputFields.country")}
                {...register("place.country")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                label={t2("addNewCargo.stepOne.inputFields.postcode")}
                {...register("place.postcode")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                label={t2("addNewCargo.stepOne.inputFields.city")}
                {...register("place.city")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                maxRows={12}
                {...register("note")}
                placeholder={t("waypointDialog.notesPlaceholder")}
              />
            </Grid>
            {!liteVersion && (
              <Grid item xs={12}>
                <div className="flex mt-1 gap-2 items-center">
                  <UFDoraCheckbox register={register("shouldShowToDriver")} />
                  <div>{t("waypointDialog.shouldShowToDriver")}</div>
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            {data !== "NEW" && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldShowDeleteConfirmDialog(true);
                }}
                disabled={isSubmitting}
                color="error"
              >
                {t("waypointDialog.deleteButton")}
              </Button>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {t("waypointDialog.cancelButton")}
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting || !isValid || !isDirty}
            >
              {t("waypointDialog.saveButton")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateEditWaypointDialog;
