import "./NewsModalContent.css";
import DoraButton from "../../Toolkit/DoraButton";
import TruckRoadToll from "./road-toll-trailer.png";
import RoadToll from "./road-toll.png";
// import Map from "./maps.png";
import { useState } from "react";

const NewsModalContent = ({ onClose }: { onClose: () => void }) => {
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 && (
        <>
          <p className="news-modal-content__helpertext">07.01.2025</p>
          <h1 className="news-modal-content__title">
            Så er vejafgiftsberegneren klar igen!
          </h1>

          <div className="flex justify-end gap-2">
            <DoraButton variant="primary" onClick={() => setStep(1)}>
              Videre
            </DoraButton>
          </div>
        </>
      )}{" "}
      {step === 1 && (
        <>
          <p className="news-modal-content__helpertext">06.01.2025</p>
          <h1 className="news-modal-content__title">
            Vejafgiftsberegner - sådan gør du
          </h1>
          <div className="news-modal-content__text">
            {/* <p>
              Lanceringen er blevet et par dage forsinket, da vi samtidig har
              opgraderet databasen for at sikre en endnu bedre oplevelse.
            </p>
            <p>
              Tak for jeres tålmodighed - <strong>og rigtig godt nytår!</strong>{" "}
              🎉
            </p> */}
            <p>Der er 2 nye indstillinger på de dine biler:</p>
            <ul>
              <li>Tilladt totaltvægt</li>
              <li>CO2-emissionsklasse</li>
            </ul>

            <div className="news-modal-content__img-container">
              <img
                src={TruckRoadToll}
                alt="Knapper"
                style={{ width: "98%", marginBottom: 2 }}
              />
              {/* <p className="news-modal-content__helpertext">
                Billede: Sådan ser feltet på den ordren ud.
              </p> */}
            </div>
            <p className="news-modal-content__helpertext">
              Du kan finde dine{" "}
              <a
                href="https://motorregister.skat.dk/dmr-kerne/koeretoejdetaljer/visKoeretoej"
                target="_blank"
              >
                CO2-emissionsklasser
              </a>{" "}
              på Motorregistret under "tekniske oplysninger". (Åbner i nyt
              vindue)
              {/* <strong>Sådan gør du:</strong> Gå til indstillinger ved at klikke
              på dit navn oppe i højre hjørne. Klik så på menupunktet
              "Opsætning" for at ændre dato og visning af ladmeter. */}
            </p>
          </div>
          <div className="flex justify-end gap-2">
            <DoraButton variant="primary" onClick={() => setStep(0)}>
              Tilbage
            </DoraButton>
            <DoraButton variant="primary" onClick={() => setStep(2)}>
              Videre
            </DoraButton>
            {/* <DoraButton variant="primary" onClick={onClose}>
              Ok
            </DoraButton> */}
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <p className="news-modal-content__helpertext">06.01.2025</p>
          <h1 className="news-modal-content__title">
            Vejafgiftsberegner - sådan gør du
          </h1>
          <div className="news-modal-content__text">
            <p>
              Klik "Beregn" oppe i højre hjørne på en ordre. Mens den beregner
              kan du lukke ordren og arbejde videre med andre ting.
            </p>
            <p>
              Ændrer du på ruten, skal den genberegnes ved at trykke på samme
              knap.
            </p>
            <p>
              Se videovejledning under "3. Ekstra" i{" "}
              <a
                href="https://app.doranordic.dk/help-and-support"
                target="_blank"
              >
                Hjælp og support
              </a>
              .
            </p>
            {/*  */}
          </div>
          <div className="news-modal-content__img-container">
            <img
              src={RoadToll}
              alt="Map"
              style={{ width: "90%", marginBottom: 20 }}
            />
            {/* <p className="news-modal-content__helpertext">
              Eksempel på kort fra live view
            </p> */}
          </div>
          {/* <p className="news-modal-content__helpertext">
            <strong>Sådan gør du:</strong> Gå til "Kunder" under indstillinger
            og klik på en kunde. Her kan du tilpasse skabelonen til den enkelte
            kunde.
          </p> */}
          <div className="flex justify-end gap-2">
            <DoraButton variant="primary" onClick={() => setStep(1)}>
              Tilbage
            </DoraButton>
            <DoraButton variant="primary" onClick={onClose}>
              Ok
            </DoraButton>
          </div>
        </>
      )}
    </>
  );
};

export default NewsModalContent;
