import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { CloseButton } from "../../dispatching-planning/common/close-button";
import { useFeature } from "../../../hooks";
import NewsModalContent from "./NewsModalContent";
import "./index.css";
import { selectUser } from "../../../ducks/auth/selectors";
import { useSelector } from "../../../redux-store";

const NEWS_MODAL_LAST_SEEN = "newsModalLastSeen";

// change this to the present date every time you want to trigger the modal
const dateToTriggerNewsModal = new Date("2025-01-08"); // yyyy.MM.dd

const NewsModal = () => {
  const [shouldShow, setShouldShow] = useState(false);
  const loggedInUser = useSelector(selectUser);
  const isNewsModalEnabled = useFeature("news-modal");
  useEffect(() => {
    const lastSeen = window.localStorage.getItem(NEWS_MODAL_LAST_SEEN);
    if (lastSeen) {
      const lastSeenDate = new Date(lastSeen);
      if (lastSeenDate.getTime() < dateToTriggerNewsModal.getTime()) {
        setShouldShow(true);
      }
    } else {
      setShouldShow(true);
    }
  }, []);

  const closeModalAndSaveLastSeenDate = () => {
    setShouldShow(false);
    const now = new Date();
    window.localStorage.setItem(NEWS_MODAL_LAST_SEEN, now.toISOString());
  };

  return (
    <>
      {isNewsModalEnabled && loggedInUser && shouldShow && (
        <Dialog
          open
          // onClose={closeModalAndSaveLastSeenDate}
        >
          <div className="news-modal">
            <div className="news-modal__close-button-wrapper">
              <CloseButton onClick={closeModalAndSaveLastSeenDate} />
            </div>
            <NewsModalContent onClose={closeModalAndSaveLastSeenDate} />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default NewsModal;
