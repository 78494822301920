import { useCallback, useEffect, useState } from "react";
import { InnerMap } from "../../Components/dispatching-planning/map/map";
import { useSearchParams } from "react-router-dom";

const WKTCoordsToHerePolyline = (coords: string) => {
  // Converts a string in the form of
  // lon lat, lon lat, lon lat
  // To Here linestring
  const asNumbers = coords
    .split(",")
    .map((set) => {
      return set.split(" ");
    })
    .flatMap(([lon, lat]) => [parseFloat(lat), parseFloat(lon)]);
  return H.geo.LineString.fromLatLngArray(asNumbers);
};

// wkt = well-known text, standard GIS text representation format
const wktLinestringToHlinestring = (value: string) => {
  const x = value.substring("LINESTRING(".length, value.length - 1);
  // This is slightly odd, but `fromLatLngArray` expects an single
  // dimension array of lat,lon,lat,lon, ... (with an even no of elements
  // obviously)
  const coords = x
    .split(",")
    .map((set) => {
      return set.split(" ");
    })
    .flatMap(([lon, lat]) => [parseFloat(lat), parseFloat(lon)]);
  return H.geo.LineString.fromLatLngArray(coords);
};

const showSegment = (segment: any) => {
  const start = segment.start;
  const stop = segment.stop;
  let res = segment.externalId;
  if (start && stop) {
    if (start.lat > stop.lat) {
      res += "\nNorth -> South";
    } else {
      res += "\nSouth -> North";
    }
    if (start.lon > stop.lon) {
      res += "\nEast -> West";
    } else {
      res += "\nWest -> East";
    }
  }
  alert(res);
};

const wktPolygonToHPolygon = (value: string) => {
  // console.log("PARSE POLYGON");
  const x = value.substring(
    "POLYGON((".length,
    value.length - 2 /* Strip the last ')' */
  );
  const lines = x.split("),(");
  if (lines.length > 1) {
    alert("Hmmm, should have been only one line");
  }

  if (lines.length < 1) {
    return null;
  }
  return [
    new H.map.Polygon(WKTCoordsToHerePolyline(lines[0]), {
      style: {
        // fillColor: "#FFFFCC80",
        strokeColor: "#829",
        lineWidth: 8,
      },
    }),
  ];
};

const MapTestPage = () => {
  const [search] = useSearchParams();
  const q = search.get("q") || "";
  const [val, setValue] = useState(q);
  const [linestrings, setLinestrings] = useState<
    H.geo.MultiLineString | undefined
  >(undefined);
  const [objects, setObjects] = useState<H.map.Object[] | null>(null);
  const [markers, setMarkers] = useState<H.map.Marker[]>([]);
  const [selectValue, setSelectValue] = useState("here-route");
  const [segment, setSegment] = useState<any>(null);
  const onSelectChange = (
    e: any /*React.SyntheticEvent<HTMLSelectElement>*/
  ) => {
    // console.log(e.target);
    // reset();
    const selectValue = e.target.value;
    setSelectValue(selectValue);
    updateSegment(segment, selectValue);
  };
  const setGeoline = useCallback(
    (geoline: H.geo.LineString) => {
      const newLinestring = new H.geo.MultiLineString([geoline]);
      setLinestrings(newLinestring);
      // console.log("Linestring updated");
    },
    [setLinestrings]
  );
  const setMarkersForSegment = useCallback(
    (segment: any) => {
      if (!segment) {
        return;
      }
      const size = { w: 30, h: 30 };
      // we need to specify the correct hit area as the default one for custom icon is rectangular shape
      const hitArea = new H.map.HitArea(H.map.HitArea.ShapeType.CIRCLE, [
        size.w / 2,
        size.h / 2,
        size.w / 2,
      ]);

      const startMarker = new H.map.Marker(
        {
          lat: segment.start.lat,
          lng: segment.start.lon,
        },
        {
          icon: new H.map.Icon(
            `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" fill="FILL_COLOR" opacity=".8"/>
                <circle cx="50" cy="50" r="4" fill="black"/></svg>`,
            {
              size,
              anchor: { x: size.w / 2, y: size.h / 2 },
              hitArea,
            }
          ),
        }
      );
      // const stopMarker = new H.map.Marker(
      //   {
      //     lat: segment.start.lat,
      //     lng: segment.start.lon,
      //   },
      //   {
      //     icon: new H.map.Icon(
      //       `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 100 100">
      //             <circle cx="50" cy="50" r="50" fill="FILL_COLOR" opacity=".8"/>
      //             <circle cx="50" cy="50" r="4" fill="black"/></svg>`,
      //       //   .replace(
      //       //   "FILL_COLOR",
      //       //   "rgba(255, 100,100,1)"
      //       // )
      //       {
      //         size,
      //         anchor: { x: size.w / 2, y: size.h / 2 },
      //         hitArea,
      //       }
      //     ),
      //   }
      // );
      setMarkers([
        startMarker,
        // stopMarker,
        new H.map.Marker({
          lat: segment.stop.lat,
          lng: segment.stop.lon,
        }),
      ]);
    },
    [setMarkers]
  );
  const reset = useCallback(() => {
    // setSegment(null);
    setObjects(null);
    setMarkers([]);
    setLinestrings(undefined);
  }, [setObjects, setMarkers, setLinestrings]);
  const updateSegment = useCallback(
    (segment: any, select: any) => {
      // console.log("UPDATE SEGMENT", segment);
      setMarkersForSegment(segment);
      setLinestrings(undefined);
      if (segment) {
        setMarkersForSegment(segment);
      }
      switch (select) {
        case "markers":
          setGeoline(
            H.geo.LineString.fromLatLngArray([
              segment.start.lat,
              segment.start.lon,
              segment.stop.lat,
              segment.stop.lon,
            ])
          );
          break;
        case "here-route":
          setGeoline(
            H.geo.LineString.fromFlexiblePolyline(segment.hereLinestring[0])
          );
          break;
        case "here-route-reverse":
          setGeoline(
            H.geo.LineString.fromFlexiblePolyline(
              segment.hereLinestringReverse[0]
            )
          );
          break;
        case "here-pedestrian-route":
          setGeoline(
            H.geo.LineString.fromFlexiblePolyline(
              segment.herePedestrianLinestring[0]
            )
          );
          break;
        case "here-pedestrian-route-reverse":
          setGeoline(
            H.geo.LineString.fromFlexiblePolyline(
              segment.herePedestrianLinestringReverse[0]
            )
          );
          break;
        case "linestring":
          if (segment?.wkt?.linestring) {
            setGeoline(wktLinestringToHlinestring(segment.wkt.linestring));
          }
          break;
        case "linestring-reverse":
          if (segment?.wkt?.linestringReverse) {
            setGeoline(
              wktLinestringToHlinestring(segment.wkt.linestringReverse)
            );
          }
          break;
        case "linestrings":
          const strings = [
            segment?.wkt?.linestring &&
              wktLinestringToHlinestring(segment.wkt.linestring),
            segment?.wkt?.linestringReverse &&
              wktLinestringToHlinestring(segment.wkt.linestringReverse),
          ].filter(Boolean);
          if (strings.length) {
            setLinestrings(new H.geo.MultiLineString(strings));
          }
          break;
        case "linestring-buffer":
          if (segment?.wkt?.linestringBuffer) {
            setObjects(wktPolygonToHPolygon(segment.wkt.linestringBuffer));
          }
          break;
        case "linestring-reverse-buffer":
          if (segment?.wkt?.linestringReverseBuffer) {
            setObjects(
              wktPolygonToHPolygon(segment.wkt.linestringReverseBuffer)
            );
          }
          break;
      }
    },
    [setGeoline, setMarkersForSegment]
  );
  const updateLinestring = useCallback(
    (value: string) => {
      reset();
      try {
        if (value === "ALL") {
          reset();
          fetch(`/api/dk-toll-roads/segments`)
            .then((r) => {
              if (!r.ok) {
                throw new Error("Error getting data");
              }
              return r.json();
            })
            .then((data) => {
              // console.log("DATA!", data);
              const objects = data.data
                .flatMap((segment: any) => {
                  const status =
                    !segment.linestring && !segment.linestringReverse
                      ? "NONE"
                      : segment.completed2 && !segment.suspiscious
                        ? "FULL"
                        : segment.suspiscious
                          ? "SUSP"
                          : "HALF";

                  // (segment.linestring && segment.linestringReverse) ||
                  // (segment.completed && !segment.suspiscious)
                  //   ? "FULL"
                  //   : segment.linestring || segment.linestringReverse
                  //     ? "HALF"
                  //     : "NONE";
                  return [
                    segment.linestring && [
                      segment,
                      H.geo.LineString.fromLatLngArray(
                        segment.linestring.flatMap(([x, y]: any) => [y, x])
                      ),
                      status,
                    ],
                    segment.linestringReverse && [
                      segment,
                      H.geo.LineString.fromLatLngArray(
                        segment.linestringReverse.flatMap(([x, y]: any) => [
                          y,
                          x,
                        ])
                      ),
                      status,
                    ],
                    !segment.linestring &&
                      !segment.linestringReverse &&
                      segment.start &&
                      segment.stop && [
                        segment,
                        H.geo.LineString.fromLatLngArray([
                          segment.start.lat,
                          segment.start.lon,
                          segment.stop.lat,
                          segment.stop.lon,
                        ]),
                        status,
                      ],
                  ].filter(Boolean);
                })
                .map(([segment, linestring, line]: any) => {
                  let style;
                  switch (line) {
                    case "FULL":
                      style = {
                        lineWidth: 5,
                        strokeColor: "rgba(0, 128, 255, 0.7)",
                      };
                      break;
                    case "HALF":
                      style = {
                        lineWidth: 5,
                        strokeColor: "rgba(0, 255, 255, 0.7)",
                      };
                      break;
                    case "SUSP":
                      style = {
                        lineWidth: 5,
                        strokeColor: "rgba(0, 0, 0, 0.7)",
                      };
                      break;
                    case "NONE":
                      style = {
                        lineWidth: 5,
                        strokeColor: "rgba(255, 0, 0, 1)",
                      };
                  }
                  const mapObject = new H.map.Polyline(linestring, {
                    style,
                    // style: line
                    //   ? {
                    //       lineWidth: 3,
                    //       strokeColor: "rgba(155, 128, 0, 0.7)",
                    //       //lineTailCap: "arrow-tail",
                    //       //lineHeadCap: "arrow-head",
                    //     }
                    //   : {
                    //       lineWidth: 8,
                    //       strokeColor: "rgba(0, 128, 255, 0.7)",
                    //       //lineTailCap: "arrow-tail",
                    //       //lineHeadCap: "arrow-head",
                    //     },
                  });
                  // console.log("Add event listener", mapObject);
                  const listener = () => {
                    showSegment(segment);
                  };
                  mapObject.addEventListener("tap", listener);
                  // mapObject.addEventListener("click", listener);
                  return mapObject;
                });

              // console.log("OBJECTS", objects);
              setObjects(objects);
            });
        } else if (/^\d+_\d+/.test(value)) {
          console.log("Fetch one", value);
          const segmentIds = value.split(",").map((x) => x.trim());
          if (segmentIds.length > 1) {
            const segmentIds = value.split(",").map((x) => x.trim());
            console.log("Fetch many", segmentIds);
            Promise.all(
              segmentIds.map((id) =>
                fetch(`/api/dk-toll-roads/segment?external_id=${id}`)
                  .then((r) => {
                    if (!r.ok) {
                      throw new Error("Error getting linestring");
                    }
                    return r.json();
                  })
                  .then(({ data }) => data)
              )
            ).then((segments) => {
              const lines = segments
                .flatMap((segment) => [
                  segment?.wkt?.linestring &&
                    wktLinestringToHlinestring(segment.wkt.linestring),
                  segment?.wkt?.linestringReverse &&
                    wktLinestringToHlinestring(segment.wkt.linestringReverse),
                ])
                .filter(Boolean);
              const mapObjects = lines.map((line) => new H.map.Polyline(line));
              setObjects(mapObjects);
            });
          } else {
            fetch(`/api/dk-toll-roads/segment?external_id=${value}`)
              .then((r) => {
                if (!r.ok) {
                  throw new Error("Error getting linestring");
                }
                return r.json();
              })
              .then(({ data }) => data)
              .then((segment) => {
                // console.log({ segment });
                setMarkersForSegment(segment);
                setSegment(segment);
                updateSegment(segment, selectValue);
              });
          }
        } else {
          reset();
          if (value.startsWith("POLYGON")) {
            setObjects(wktPolygonToHPolygon(value));
          } else if (value.startsWith("LINESTRING")) {
            const ls = wktLinestringToHlinestring(value);
            setGeoline(ls);
          } else {
            setGeoline(H.geo.LineString.fromFlexiblePolyline(value));
          }
        }
      } catch (err) {
        // console.error("Error updating line string", err);
      }
    },
    [
      setSegment,
      setObjects,
      selectValue,
      updateSegment,
      setGeoline,
      reset,
      setMarkersForSegment,
    ]
  );

  useEffect(() => {
    if (q) {
      updateLinestring(q);
    }
  }, [q, updateLinestring]);

  // Get scroll wheel working
  const customizeBehaviour = useCallback((b: H.mapevents.Behavior) => {}, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "1fr",
        gap: "2rem",
        padding: "2rem",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div>
          <label className="block w-full" htmlFor="input-field">
            Segment ID, WKT Linestring, or here map flexible polyline
          </label>
          <input
            // style={{ display: "block", width: "100%" }}
            className="block w-full"
            value={val}
            onChange={(e) => {
              setValue(e.target.value);
              updateLinestring(e.target.value);
            }}
          />
        </div>
        <select
          onChange={onSelectChange}
          value={selectValue}
          disabled={segment === null}
        >
          <option value="markers">Markers</option>
          <option value="here-route">Here route</option>
          <option value="here-route-reverse">Here route reverse</option>
          <option value="here-pedestrian-route">Here pedestrian route</option>
          <option value="here-pedestrian-route-reverse">
            Here pedestrian route reverse
          </option>
          <option value="linestring">Linestring</option>
          <option value="linestring-reverse">Linestring reverse</option>
          <option value="linestrings">Both Linestrings</option>
          <option value="linestring-buffer">Linestring buffer</option>
          <option value="linestring-reverse-buffer">
            Linestring reverse buffer
          </option>
        </select>
      </div>

      <InnerMap
        lineStrings={linestrings}
        markers={markers}
        mapObjects={objects}
        customizeBehaviour={customizeBehaviour}
      />
    </div>
  );
};

export default MapTestPage;
