import { Box } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import {
  beginDragCargo,
  beginDragStop,
  endDrag,
} from "../../../ducks/app/dispatching";
import { useAppDispatch } from "../../../redux-store";
import { moveTo } from "../../../ducks/app/route-info/stops";
import { useRef } from "react";
import { WaypointData } from "../../../ducks/app/route-info/stops/types";
import { RouteStopData } from "../../../ducks/app/route-info/stops/selectors";
import RouteWaypoint from "./RouteWaypoint";
import CargoStop from "./CargoStop";

const RouteStop = ({
  routeStop,
  onEditWaypoint,
}: {
  routeStop: RouteStopData;
  onEditWaypoint: (waypoint: WaypointData) => void;
}) => {
  switch (routeStop.type) {
    case "WAYPOINT":
      return (
        <RouteWaypoint
          waypoint={routeStop}
          onEditButtonClick={onEditWaypoint}
        />
      );
    case "CARGO_STOP":
      return <CargoStop stop={routeStop} />;
    default:
      // Cases a typescript error if not all types are handled
      const x: never = routeStop; // eslint-disable-line
      return null;
  }
};

const DraggableRouteStop = ({
  routeStop,
  routeId,
  onEditWaypoint,
}: {
  routeStop: RouteStopData;
  routeId: string;
  onEditWaypoint: (waypoint: WaypointData) => void;
}) => {
  const dragItemType = routeStop.type === "WAYPOINT" ? "WAYPOINT" : "STOP";

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging, draggedStopId }, drag] = useDrag(
    () => ({
      type: dragItemType,
      item: () => {
        switch (routeStop.type) {
          case "WAYPOINT": {
            dispatch(
              beginDragStop({
                routeStopId: routeStop.id,
                routeId,
              })
            );
            return {
              routeStopId: routeStop.id,
              routeId,
            };
          }
          case "CARGO_STOP": {
            dispatch(
              beginDragCargo({
                cargoId: routeStop.cargoId,
                routeStopId: routeStop.id,
                routeId,
              })
            );
            return {
              cargoId: routeStop.cargoId,
              routeStopId: routeStop.id,
              routeId,
            };
          }
        }
      },
      end: () => {
        dispatch(endDrag());
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        draggedStopId: monitor.getItem()?.routeStopId,
      }),
    }),
    [routeStop.id]
  );

  const opacity = isDragging && draggedStopId === routeStop.id ? 0.5 : 1;
  const [, drop] = useDrop<{ routeStopId: string }, void>(
    () => ({
      accept: ["STOP", "WAYPOINT"],
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
        if (routeStop.id === item.routeStopId) {
          return;
        }
        const clientOffset = monitor.getClientOffset();
        if (clientOffset === null) {
          return;
        }
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        const position = hoverClientY < hoverMiddleY ? "ABOVE" : "BELOW";
        dispatch(
          moveTo({
            sourceStopId: item.routeStopId,
            destStopId: routeStop.id,
            position,
          })
        );
      },
    }),
    [routeStop.id]
  );
  drag(drop(ref));

  return (
    <Box
      ref={ref}
      sx={{
        userSelect: "none",
        cursor: "grab",
        opacity,
      }}
    >
      <RouteStop routeStop={routeStop} onEditWaypoint={onEditWaypoint} />
    </Box>
  );
};

export default DraggableRouteStop;
