import { useSelector } from "../../redux-store";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { selectFormData } from "../../ducks/app/teams-dialog/selectors";
import { useDialogActions } from "../../ducks/app/teams-dialog/hooks";
import { useTranslation } from "react-i18next";
import { Form, Field, Formik } from "formik";
import { MuiTextField } from "../../Components/formik";

type DialogContentData = { name: string; description: string };

const TeamDialogContent = ({
  initialData,
}: {
  initialData: DialogContentData;
}) => {
  const { t } = useTranslation("settings");
  const dialogActions = useDialogActions();
  return (
    <Dialog open={true} onClose={dialogActions.close}>
      <Formik
        onSubmit={async (formValues) => {
          dialogActions.submit(formValues);
        }}
        initialValues={initialData}
      >
        {({ isSubmitting, values, ...rest }) => {
          return (
            <Form>
              <DialogTitle>{t("teamDialog.addNewDialogTitle")}</DialogTitle>
              <DialogContent>
                <Field
                  component={MuiTextField}
                  required
                  label={t("team.name")}
                  name="name"
                  autoFocus
                />
                <Field
                  component={MuiTextField}
                  label={t("team.description")}
                  name="description"
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} type="submit">
                  {t("team.saveButton")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const TeamDialog = () => {
  const data = useSelector(selectFormData);
  return data && <TeamDialogContent initialData={data} />;
};

export default TeamDialog;
