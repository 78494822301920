import { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { Flipper, Flipped } from "react-flip-toolkit";
import { useDrop } from "react-dnd";
import { Box, Stack } from "@mui/material";
import { commit, mouseOut } from "../../../ducks/app/route-info/stops";
import {
  RouteStopData,
  selectedDraggedStopId,
} from "../../../ducks/app/route-info/stops/selectors";
import { hover, commit as commitCargo } from "../../../ducks/app/dispatching";
import CreateEditWaypointDialog from "./CreateEditWaypointDialog";
import * as actions from "../../../ducks/app/route-info/stops";
import DraggableRouteStop from "./DraggableRouteStop";
import { WaypointData } from "../../../ducks/app/route-info/stops/types";
import { useStableNavigate } from "../../StableNavigateContext";
import { CARGO_LIST_DRAGGABLE_CARGO } from "../../../pages/DispatchPage/CargoList/CargoCard";
import { useFeature } from "../../../hooks";

interface Props {
  routeId: string;
  stopsAndWaypoints: RouteStopData[];
}

export const RouteInfo = ({ routeId, stopsAndWaypoints }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("dispatching");
  const navigate = useStableNavigate();
  const liteVersion = useFeature("lite-version");

  const [createEditWaypointData, setCreateEditWaypointData] = useState<
    WaypointData | "NEW" | null
  >(null);
  const draggedStopId = useSelector(selectedDraggedStopId);

  const onAddCargo = useCallback(() => {
    navigate({ hash: "#cargo=new&route=" + routeId });
  }, [routeId, navigate]);

  const onAddWaypointClick = () => setCreateEditWaypointData("NEW");

  const onEditWaypointClick = (data: WaypointData) =>
    setCreateEditWaypointData(data);

  const [{ isOver, itemType }, drop] = useDrop(
    () => ({
      accept: [CARGO_LIST_DRAGGABLE_CARGO, "STOP", "WAYPOINT"],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        itemType: monitor.getItemType(),
        // isDragging: monitor.
      }),
      hover: (_item, monitor) => {
        if (monitor.getItemType() === CARGO_LIST_DRAGGABLE_CARGO) {
          dispatch(
            hover({
              destRouteId: routeId,
            })
          );
        }
      },
      drop: (_item: any, monitor) => {
        if (monitor.getItemType() === CARGO_LIST_DRAGGABLE_CARGO) {
          dispatch(commitCargo());
        } else {
          dispatch(commit());
        }
      },
    }),
    [routeId]
  );
  useEffect(() => {
    if (!isOver) {
      dispatch(mouseOut());
    }
  }, [isOver, dispatch]);

  const onCreateEditWaypointClose = (shouldRefresh?: boolean) => {
    setCreateEditWaypointData(null);
    if (shouldRefresh) {
      dispatch(actions.loadRoute(routeId));
    }
  };

  return (
    <>
      {createEditWaypointData && (
        <CreateEditWaypointDialog
          routeId={routeId}
          data={createEditWaypointData}
          onClose={() => onCreateEditWaypointClose()}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={8.5}>
          <Typography variant="h5">{t("openRouteView.routeInfo")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {t(
              liteVersion
                ? "openRouteView.accumulatedLoadMeterWeight"
                : "openRouteView.accumulatedLoadMeterAbbreviation"
            )}
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Box
        ref={drop}
        sx={{
          margin: "0.5rem 0",
          border:
            isOver && itemType === CARGO_LIST_DRAGGABLE_CARGO
              ? "1px dashed grey"
              : "1px solid rgba(0, 0, 0, 0)",
        }}
      >
        <Flipper flipKey={stopsAndWaypoints}>
          {stopsAndWaypoints.map((routeStop, index) => {
            return (
              <Flipped key={routeStop.id} flipId={routeStop.id}>
                <Box
                  sx={{
                    opacity: routeStop.id === draggedStopId ? 0.5 : 1,
                  }}
                >
                  {index > 0 && <Divider />}
                  <DraggableRouteStop
                    routeStop={routeStop}
                    routeId={routeId}
                    onEditWaypoint={onEditWaypointClick}
                  />
                </Box>
              </Flipped>
            );
          })}
        </Flipper>
      </Box>
      <Stack spacing={2} sx={{ my: 1 }} direction="row">
        <Button variant="contained" color="primary" onClick={onAddCargo}>
          {t("undispatchedCargo.buttonAddShipment")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddWaypointClick}
        >
          {t("openRouteView.addWaypointButton")}
        </Button>
      </Stack>
    </>
  );
};
