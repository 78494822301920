import { useSelector } from "react-redux";
import "./IncomeHandler.scss";
import { selectInvoiceLines } from "../../../../../ducks/data/deliveries/selectors";
import { useL10n } from "../../../../../l10n";
import { Fragment, useMemo, useRef, useState } from "react";
import AddInvoiceLineRow from "./AddInvoiceLineRow";
import InvoiceLineEditingRow from "./EditInvoiceLineRow";
import { useTranslation } from "react-i18next";
import DoraButton from "../../../../Toolkit/DoraButton";
import Decimal from "decimal.js-light";
import { faLock, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../../../../ducks/data/deliveries";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useAppDispatch } from "../../../../../redux-store";
import { DoraCheckbox } from "../../../../Toolkit/DoraCheckbox";
import ProductCellValue from "./ProductCellValue";
import UnitCellValue from "./UnitCellValue";
import { useDrag, useDrop } from "react-dnd";

const INVOICE_LINE = "INVOICE_LINE";

const IncomeHandler = (props: {
  deliveryId: string;
  selectedLineIds: string[];
  showAddNewLine: boolean;
  onCancelNewLine: () => void;
  onSelectedLinesChange: (lineIds: string[]) => void;
  onLinesCreated: (newLineIds: string[]) => void;
  clientId?: string;
}) => {
  const { t } = useTranslation(["invoicing", "components"]);
  const {
    deliveryId,
    onSelectedLinesChange,
    selectedLineIds,
    showAddNewLine,
    onCancelNewLine,
    clientId,
  } = props;
  const lines = useSelector(selectInvoiceLines(deliveryId));
  const dispatch = useAppDispatch();

  const [editingLineId, setEditingLineId] = useState<string | null>(null);
  const lineViewModels = useMemo(
    () =>
      lines.map((line) => ({
        line,
        selected: selectedLineIds.includes(line.id),
        editing: editingLineId === line.id,
      })),
    [lines, selectedLineIds, editingLineId]
  );

  const allLinesChecked = useMemo(
    () => selectedLineIds.length === lines.filter((l) => !l.locked).length,
    [selectedLineIds, lines]
  );

  const setSelected = (id: string, selected: boolean) => {
    const previousSelection = [...selectedLineIds];
    if (selected) {
      if (previousSelection.includes(id)) {
        return;
      }
      onSelectedLinesChange([...previousSelection, id]);
    } else {
      onSelectedLinesChange(previousSelection.filter((x) => x !== id));
    }
  };

  const onEditClick = (lineId: string) => {
    setEditingLineId(lineId);
  };

  const onAllCheckboxClick = (v: boolean) => {
    if (allLinesChecked) {
      onSelectedLinesChange([]);
      return;
    }
    onSelectedLinesChange(lines.filter((x) => !x.locked).map((x) => x.id));
  };

  const onAfterCreate = (newLineIds: string[]) => {
    props.onLinesCreated(newLineIds);
  };

  const onDeleteClick = (lineId: string) => {
    dispatch(
      actions.deleteLine({
        deliveryId,
        lineId,
      })
    ).unwrap();
  };

  return (
    <>
      <div className="income-grid" role="grid">
        <div role="row" className="income-grid__row">
          <div role="gridcell" className="income-grid__heading">
            <div className="income-grid-heading__checkbox">
              <DoraCheckbox
                checked={allLinesChecked}
                onChange={onAllCheckboxClick}
              />
            </div>
          </div>
          <div
            role="gridcell"
            className="income-grid__heading"
            id="income-grid-heading-product"
          >
            {t("invoicing:invoiceLine.code")}
          </div>
          <div role="gridcell" className="income-grid__heading">
            {t("invoicing:invoiceLine.description")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.quantity")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading"
            id="income-grid-heading-unit"
          >
            {t("invoicing:invoiceLine.unit")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.amount")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
            id="income-grid-heading-percentage"
          >
            {t("invoicing:invoiceLine.percentage")}
          </div>
          <div role="gridcell" className="income-grid__heading">
            {t("invoicing:invoiceLine.created")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.sum")}
          </div>
          <div role="gridcell" className="income-grid__heading"></div>
        </div>
        {lineViewModels.map(({ line, selected, editing }, i) => (
          <InvoiceLineWrapper
            key={line.id}
            line={line}
            selected={selected}
            editing={editing}
            clientId={clientId}
            deliveryId={deliveryId}
            setEditingLineId={setEditingLineId}
            setSelected={setSelected}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            index={i}
          />
        ))}
        {showAddNewLine && (
          <AddInvoiceLineRow
            onCancel={onCancelNewLine}
            deliveryId={deliveryId}
            clientId={clientId}
            onAfterCreate={onAfterCreate}
          />
        )}
      </div>
    </>
  );
};

const InvoiceLineWrapper = ({
  line,
  selected,
  editing,
  deliveryId,
  clientId,
  setEditingLineId,
  setSelected,
  onEditClick,
  onDeleteClick,
  index,
}: {
  line: any;
  selected: boolean;
  editing: boolean;
  deliveryId: string;
  clientId?: string;
  setEditingLineId: (id: string | null) => void;
  setSelected: (id: string, selected: boolean) => void;
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  index: number;
}) => {
  const { t } = useTranslation(["invoicing", "components"]);
  const l10n = useL10n();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const [hoverProp, setHoverProp] = useState<"ABOVE" | "BELOW" | null>(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: INVOICE_LINE,
    drop: (item: { line: any; index: number }, monitor) => {
      if (!ref.current) {
        return;
      }

      setHoverProp(null);

      const dragIndex = item.index;
      const hoverIndex = index;

      const clientOffset = monitor.getClientOffset();
      if (clientOffset === null) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const position = hoverClientY < hoverMiddleY ? "ABOVE" : "BELOW";

      const dropPosition = position === "ABOVE" ? hoverIndex  : hoverIndex; // +1 but not working as should

      if (dragIndex === dropPosition) {
        return;
      }

      dispatch(
        actions.updateLineOrderNumber({
          deliveryId,
          lineId: item.line.id,
          orderNumber: dropPosition,
        })
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (!ref.current || !monitor.isOver() || item.index === index) {
        setHoverProp(null);
        return;
      }

      const clientOffset = monitor.getClientOffset();
      if (clientOffset === null) {
        setHoverProp(null);
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const position = hoverClientY < hoverMiddleY ? "ABOVE" : "BELOW";

      if (position !== hoverProp) {
        setHoverProp(position);
      }
    },
    end: () => {
      setHoverProp(null);
    },
  }), [index]);

  const [{ isDragging }, drag] = useDrag({
    type: INVOICE_LINE,
    item: { type: INVOICE_LINE, line, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoverProp(null);
    },
  }, [index]);

  drag(drop(ref));

  return (
    <Fragment>
      {editing ? (
        <InvoiceLineEditingRow
          line={line}
          deliveryId={deliveryId}
          clientId={clientId}
          onCancel={() => setEditingLineId(null)}
          onAfterEdit={() => setEditingLineId(null)}
        />
      ) : (
        <div
          role="row"
          className="income-grid__row income-grid__row--draggable"
          ref={ref}
          style={{
            opacity: isDragging ? 0.5 : 1,
            paddingBottom: hoverProp === "BELOW" && isOver ? "2rem" : "0",
            paddingTop: hoverProp === "ABOVE" && isOver ? "2rem" : "0",
            transition: "padding 0.2s ease-in-out", // Added transition for smooth animation
          }}
        >
          <div
            role="gridcell"
            className="flex items-center income-grid-row__checkbox-cell"
          >
            <DoraCheckbox
              checked={selected}
              disabled={line.locked}
              onChange={() => !line.locked && setSelected(line.id, !selected)}
            />
          </div>
          <div
            role="gridcell"
            className="flex items-center income-grid__grid-cell"
            aria-describedby="income-grid-heading-product"
          >
            {line.productId && <ProductCellValue productId={line.productId} />}
          </div>
          <div
            role="gridcell"
            className="flex items-center income-grid__grid-cell"
          >
            {line.description}
          </div>
          <div role="gridcell" className="flex items-center justify-end">
            {l10n.formatNumber(line.quantity)}
          </div>
          <div role="gridcell" className="flex items-center">
            {line.unitId && <UnitCellValue unitId={line.unitId} />}
          </div>
          <div role="gridcell" className="flex items-center justify-end">
            {l10n.formatCurrency(line.amount)}
          </div>
          <div
            role="gridcell"
            className="flex items-center justify-end"
            aria-describedby="income-grid-heading-percentage"
          >
            {line.percentage && l10n.formatPercentage(line.percentage)}
          </div>
          <div role="gridcell" className="flex items-center">
            {l10n.formatDate(line.date)}
          </div>
          <div role="gridcell" className="flex items-center justify-end">
            {l10n.formatCurrency(
              new Decimal(line.amount).mul(new Decimal(line.quantity))
            )}
          </div>
          <div role="gridcell" className="flex items-center justify-end">
            {line.locked ? (
              <div style={{ padding: 8, marginRight: 8 }}>
                <FontAwesomeIcon
                  style={{ color: "var(--gray-400)" }}
                  icon={faLock}
                />
              </div>
            ) : (
              <>
                <DoraButton
                  variant="ghost"
                  style={{ color: "var(--gray-400)" }}
                  onClick={(_) => onEditClick(line.id)}
                  disabled={line.isFromRoadToll}
                  aria-label={t("components:buttonLabels.edit")}
                  title={t("components:buttonLabels.edit")}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </DoraButton>
                <DoraButton
                  variant="ghost"
                  style={{ color: "var(--gray-400)" }}
                  aria-label={t("components:buttonLabels.delete")}
                  title={t("components:buttonLabels.delete")}
                  onClick={() => onDeleteClick(line.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </DoraButton>
              </>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default IncomeHandler;
