import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../../redux-store";
import { moveCargo } from "../../dispatchActions";
import { cargoViewDecoded, loadActiveCargo } from "../../data/cargo-views";
import { updateSmartMatch } from "../../cargo";
import { deleteCargo } from "../cargo-dialog";
import * as selectors from "./selectors";
import { SortOrder, SortProperty, FilterType } from "./types";
import uniq from "lodash/uniq";
import { setFeatures } from "../../features";

const prefix = "app/dispatching";

type Item = string;

type State = {
  originalUnassignedCargoIds: string[];
  saving: boolean;
  unassignedCargoSortingValueSet: boolean;
  unassignedCargoSorting: {
    sortOrder: SortOrder;
    sortProperty?: SortProperty;
    overriddenOrder?: string[];
  };
  teamFilterOptions: Record<FilterType, string[]>;
  dragState?: {
    cargoId: string;
    sourceRouteId: string | null;
    originalIndex: number;
    currentIndex: number;
    currentRouteId: null | string;
    routeCargoIds: Record<string, Item[]>;
    committing: boolean;
  };
  routeSearchDates?: {
    from: string;
    to: string;
  };
};

const initialState: State = {
  saving: false,
  teamFilterOptions: { cargos: [], routes: [] },
  originalUnassignedCargoIds: [],
  unassignedCargoSortingValueSet: false,
  unassignedCargoSorting: {
    sortOrder: "DESC",
  },
};

export const commit = (): AppThunkAction => (dispatch, getState) => {
  const state = getState().app.dispatching.dragState;
  if (!state) {
    return;
  }
  const {
    cargoId,
    sourceRouteId,
    currentRouteId,
    currentIndex,
    originalIndex,
    committing,
  } = state;
  const destIndex =
    sourceRouteId === currentRouteId && currentIndex > originalIndex
      ? currentIndex + 1
      : currentIndex;
  if (committing) {
    return;
  }
  dispatch(slice.actions.setCommitting(true));
  return dispatch(
    moveCargo({
      sourceRouteId,
      cargoId,
      destIndex,
      destRouteId: currentRouteId,
    })
  ).finally(() => {
    dispatch(slice.actions.setCommitting(false));
  });
};

export const cancelDrag = (): AppThunkAction => (dispatch, getState) => {
  dispatch(endDrag());
};

export const hover =
  (payload: {
    destRouteId: null | string;
    hoverCargoId?: string;
    position?: "ABOVE" | "BELOW";
  }): AppThunkAction =>
  (dispatch, getState) => {
    const globalState = getState();
    const state = globalState.app.dispatching;
    if (!state.dragState) {
      return;
    }
    const { currentRouteId: sourceRouteId, cargoId } = state.dragState;
    const { destRouteId, hoverCargoId, position } = payload;
    const sourceRoute = [
      ...selectors.selectRouteCargoIds(sourceRouteId)(globalState),
    ];
    if (destRouteId === sourceRouteId && !position) {
      return;
    }
    if (destRouteId === sourceRouteId) {
      return;
    }
    let destRoute =
      sourceRouteId === destRouteId
        ? sourceRoute
        : [...selectors.selectRouteCargoIds(destRouteId)(globalState)];
    // const destIndex = (() => {
    //   if (!hoverCargoId || !position) {
    //     return 0;
    //   }
    //   return destRoute.indexOf(hoverCargoId) + (position === "BELOW" ? 1 : 0);
    // })();
    const sourceIndex = sourceRoute.indexOf(cargoId);
    destRoute.splice(destRoute.length, 0, cargoId);
    sourceRoute.splice(sourceIndex, 1);
    // if (sourceRoute === destRoute) {
    //   if (sourceIndex === destIndex) {
    //     return;
    //   }
    //   if (
    //     sourceIndex ===
    //     destIndex + (position === "ABOVE" ? -1 : position === "BELOW" ? 1 : 0)
    //   ) {
    //     return;
    //   }
    // }
    // if (sourceIndex > destIndex) {
    //   sourceRoute.splice(sourceIndex, 1);
    //   destRoute.splice(destIndex, 0, cargoId);
    // } else {
    //   destRoute.splice(destIndex, 0, cargoId);
    //   sourceRoute.splice(sourceIndex, 1);
    // }
    dispatch(
      slice.actions.update({
        cargoId,
        routes: {
          [sourceRouteId || ""]: sourceRoute,
          [destRouteId || ""]: uniq(destRoute),
        },
        currentIndex: destRoute.indexOf(cargoId),
        currentRouteId: destRouteId,
      })
    );
  };

export const endDrag = createAction(`${prefix}/end-drag`);

export const clear = createAction(`${prefix}/clear`);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setRouteSearchDates: (
      state,
      action: PayloadAction<{ from: string; to: string }>
    ) => {
      state.routeSearchDates = action.payload;
    },
    clearRouteSearchDates: (state) => {
      delete state.routeSearchDates;
    },
    setCommitting: (state, action: PayloadAction<boolean>) => {
      if (state.dragState) {
        state.dragState.committing = action.payload;
      }
    },
    setFilterOptions: (
      state,
      action: PayloadAction<{
        teamId: string;
        enabled: boolean;
        filterType: FilterType;
      }>
    ) => {
      const { teamId, enabled, filterType } = action.payload;
      const filter = state.teamFilterOptions[filterType];
      if (enabled) {
        if (!filter.includes(teamId)) {
          filter.push(teamId);
        }
      } else {
        state.teamFilterOptions[filterType] = filter.filter(
          (x) => x !== teamId
        );
      }
    },
    // TODO: following 2 sets and selectors etc. should be one
    setUnassignedCargoSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.unassignedCargoSorting.sortOrder = action.payload;
      state.unassignedCargoSortingValueSet = true;
    },
    setUnassignedCargoSortProperty: (
      state,
      action: PayloadAction<SortProperty>
    ) => {
      state.unassignedCargoSorting.sortProperty = action.payload;
      state.unassignedCargoSortingValueSet = true;
    },
    update: (
      state,
      action: PayloadAction<{
        routes: Record<string, string[]>;
        cargoId: string;
        currentIndex: number;
        currentRouteId: string | null;
      }>
    ) => {
      if (!state.dragState) {
        state.dragState = {
          committing: false,
          cargoId: action.payload.cargoId,
          currentRouteId: action.payload.currentRouteId,
          sourceRouteId: action.payload.currentRouteId,
          originalIndex: action.payload.currentIndex,
          currentIndex: action.payload.currentIndex,
          routeCargoIds: {},
        };
      }
      const { currentIndex, currentRouteId } = action.payload;
      state.dragState.routeCargoIds = {
        ...state.dragState.routeCargoIds,
        ...action.payload.routes,
      };
      state.dragState.currentIndex = currentIndex;
      state.dragState.currentRouteId = currentRouteId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFeatures, (state, action) => {
        if (state.unassignedCargoSortingValueSet) {
          return;
        }
        state.unassignedCargoSorting.sortProperty = "Læssedato";
        state.unassignedCargoSorting.sortOrder = "ASC";
        state.unassignedCargoSortingValueSet = true;
      })
      .addCase(clear, (state, action) => {
        state.teamFilterOptions.routes = [];
      })
      .addCase(loadActiveCargo.fulfilled, (state, action) => {
        state.originalUnassignedCargoIds = action.payload
          .filter((x) => !x.assignedRouteId && !x.smartMatch)
          .map((x) => x.id);
      })
      .addCase(endDrag, (state) => {
        if (state.dragState && !state.saving) {
          delete state.dragState;
        }
      })
      .addCase(updateSmartMatch.fulfilled, (state, action) => {
        const { cargoId, smartMatch } = action.meta.arg;
        const unassigned = state.originalUnassignedCargoIds;
        if (smartMatch) {
          state.originalUnassignedCargoIds = unassigned.filter(
            (x) => x !== cargoId
          );
        } else {
          unassigned.push(cargoId);
        }
      })
      .addCase(moveCargo.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(moveCargo.fulfilled, (state, action) => {
        state.saving = false;
        if (!state.dragState) {
          return;
        }
        if (!state.dragState.currentRouteId) {
          // delete state.unassignedCargoSorting.sortProperty;
          // TODO: all of this might no longer be necessary
          const undispatchedCargoIds = state.dragState.routeCargoIds[""];
          if (undispatchedCargoIds) {
            state.unassignedCargoSorting.overriddenOrder = undispatchedCargoIds;
            state.originalUnassignedCargoIds = undispatchedCargoIds;
          }
        }
        delete state.dragState;
      })
      // .addCase(getAllArchivedRoutes.fulfilled, (state, action) => {
      // for (const route of action.payload.routes) {
      // state.originalCargoIds[route.id] = route.cargo_order;
      // }
      // })
      .addCase(moveCargo.rejected, (state, action) => {
        delete state.dragState;
        state.saving = false;
      })
      .addCase(deleteCargo.fulfilled, (state, action) => {
        const cargoId = action.meta.arg;
        const cargoIds = state.originalUnassignedCargoIds;
        const index = cargoIds.indexOf(cargoId);
        if (index !== -1) {
          cargoIds.splice(index, 1);
        }
      })
      .addCase(cargoViewDecoded, (state, action) => {
        const { cargoView, myTeams } = action.payload;
        const { id, deleted, assignedRouteId, assignedTeamId } = cargoView;
        if (
          myTeams.includes(assignedTeamId) &&
          assignedRouteId === null &&
          !deleted
        ) {
          if (!state.originalUnassignedCargoIds.includes(id)) {
            state.originalUnassignedCargoIds.unshift(id);
          }
        } else {
          state.originalUnassignedCargoIds =
            state.originalUnassignedCargoIds.filter((x) => x !== id);
        }
      });
  },
});

export const beginDragStop = createAction<{
  routeId: string | null;
  routeStopId: string;
}>(`${prefix}/begin-drag-stop`);

export const beginDragCargo =
  (input: {
    cargoId: string;
    routeId: string | null;
    routeStopId?: string;
  }): AppThunkAction =>
  (dispatch, getState) => {
    const { routeStopId, cargoId, routeId } = input;
    const sourceRoute = selectors.selectRouteCargoIds(routeId)(getState());
    const currentIndex = sourceRoute.indexOf(cargoId);
    dispatch(
      slice.actions.update({
        cargoId,
        currentRouteId: routeId,
        currentIndex,
        routes: {},
      })
    );
    if (routeStopId) {
      dispatch(beginDragStop({ routeId, routeStopId }));
    }
  };

export const {
  setUnassignedCargoSortOrder,
  setUnassignedCargoSortProperty,
  setFilterOptions,
  setRouteSearchDates,
  clearRouteSearchDates,
} = slice.actions;
export default slice.reducer;
