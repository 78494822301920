import { useCallback, useEffect, useState } from "react";
import { loadTrailers } from "../../ducks/data/trailers";
import { addRoute, getAllRoutes } from "../../ducks/routes";
import { loadSessions } from "../../ducks/data/driver-sessions";
import { loadDriversActivities } from "../../ducks/data/driver-activities";
import { loadDrivers } from "../../ducks/data/drivers";
import { loadNewShipmentDate } from "../../ducks/data/org-settings";
import { useAppDispatch, useSelector } from "../../redux-store";
import { useTranslation } from "react-i18next";
import { selectRouteIds } from "../../ducks/data/route-views/selectors";
import RoutePageColumn from "../DispatchPage/RoutePageColumn";
import DoraButton from "../../Components/Toolkit/DoraButton";
import { Typography } from "@mui/material";

const LightVersionSingleRoute = () => {
  const dispatch = useAppDispatch();
  const [addRouteInProgress, setAddRouteInProgress] = useState(false);
  const [routeViewsLoaded, setRouteViewsLoaded] = useState(false);
  const { t } = useTranslation(["dispatching"]);

  const loadAllRoutes = useCallback(() => {
    setRouteViewsLoaded(false);
    dispatch(getAllRoutes())
      .unwrap()
      .then(() => {
        setRouteViewsLoaded(true);
      });
  }, [dispatch, setRouteViewsLoaded]);

  useEffect(() => {
    dispatch(loadTrailers());
    loadAllRoutes();
    dispatch(loadSessions());
    dispatch(loadDriversActivities());
    dispatch(loadDrivers());
    dispatch(loadNewShipmentDate());
  }, [dispatch, loadAllRoutes]);

  const routeViewsIds = useSelector(selectRouteIds);

  const onCreateRouteClick = () => {
    setAddRouteInProgress(true);
    dispatch(addRoute({}))
      .unwrap()
      .then(() => {
        loadAllRoutes();
      })
      .finally(() => {
        setAddRouteInProgress(false);
      });
  };

  if (!routeViewsLoaded) {
    return null;
  }

  if (routeViewsIds.length > 1) {
    return (
      <div
        className="flex flex-col items-center gap-4 justify-center"
        style={{ height: "60%" }}
      >
        {t("dispatching:liteVersion.errorContactSupport")}
      </div>
    );
  }

  if (routeViewsIds.length === 0) {
    return (
      <div
        className="flex flex-col items-center gap-4 justify-center"
        style={{ height: "60%" }}
      >
        <Typography variant="h2">
          {/* {t("dispatching:liteVersion.noRoutes")} */}
          1. Opret rute
        </Typography>
        <Typography variant="h2">
          {/* {t("dispatching:liteVersion.noRoutes")} */}
          2. Tilføj ordrer og evt. viapunkter
        </Typography>
        <Typography variant="h2">
          {/* {t("dispatching:liteVersion.noRoutes")} */}
          3. Tryk "gem rute og luk"
        </Typography>
        <DoraButton
          id="add-co2-route-button"
          variant="primary-filled"
          disabled={addRouteInProgress}
          onClick={onCreateRouteClick}
        >
          {t("dispatching:liteVersion.start")}
        </DoraButton>
      </div>
    );
  }

  const routeViewId = routeViewsIds[0];

  return (
    <div>
      <div style={{ width: "60%", margin: "0 auto" }}>
        <RoutePageColumn routeId={routeViewId} />
      </div>
    </div>
  );
};

export default LightVersionSingleRoute;
