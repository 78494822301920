import { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Autocomplete, TextField } from "@mui/material";
import { usePlacePredictions } from "../common/places-context";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../ducks/auth/selectors";
import { useSelector } from "../../../redux-store";
import { AutoSuggestItem } from "dora-shared";
import RenderParsedText from "../common/location-search-result/RenderParsedText";
import { StopPlace } from "../../../ducks/app/cargo-and-template-shared";

export type AddressChange = StopPlace & {
  place?: string;
  googlePlaceId?: string;
};

const NewPlaceSearch = (props: {
  // place: string;
  onPlaceSelected: (place: null | AddressChange) => void;
  placeError: boolean;
}) => {
  const { t } = useTranslation(["modals", "components"]);
  const me = useSelector(selectUser);
  const [results, setResults] = useState<AutoSuggestItem[]>([]);
  const { suggest } = usePlacePredictions();
  const options = results;
  const [inputValue, setInputValue] = useState("");
  const search = (input: string) => {
    setInputValue(input);
    suggest(
      input,
      {
        type: "AT",
        position: me?.searchOrigin!,
      },
      (err, res) => {
        if (err) {
          console.error(err);
          return;
        }
        setResults(res || []);
      }
    );
    // fetch(input, []);
  };
  const [value, setValue] = useState<AutoSuggestItem | null>(null);
  // useEffect(() => {
  //   // if (!props.place) {
  //     setValue(null);
  //     setInputValue("");
  //   // }
  // });
  const onChange = (
    _event: unknown,
    newValue: AutoSuggestItem | null,
    reason: string
  ) => {
    if (reason === "blur") {
      return;
    }
    setValue(newValue);
    if (!newValue) {
      props.onPlaceSelected(null);
    } else {
      newValue.lookup().then((lookupResult) => {
        const lat = lookupResult.coords?.lat;
        const lon = lookupResult.coords?.lon;
        if (lat && lon) {
          props.onPlaceSelected({
            postcode: lookupResult.postcode,
            address: lookupResult.address,
            city: lookupResult.city,
            country: lookupResult.countryCode,
            place: newValue.label,
            placeName: lookupResult.placeName,
            coord: { lon, lat },
            googlePlaceId: lookupResult.googlePlaceId,
          });
        }
      });
    }
  };
  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      noOptionsText={t("components:optionLabels.noOptions")}
      filterOptions={(x) => x}
      options={options}
      value={value}
      autoSelect
      // isOptionEqualToValue={(o, v) => {
      // return o?.getPlaceId() === v?.getPlaceId();
      // }}
      selectOnFocus
      clearOnBlur={false}
      clearOnEscape
      fullWidth
      autoHighlight
      // includeInputInList
      // filterSelectedOptions
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => search(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("modals:addNewCargo.stepOne.inputFields.searchPlace")}
          error={props.placeError}
          helperText={
            props.placeError &&
            t("modals:addNewCargo.stepOne.formValidation.locationRequired")
          }
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                component={LocationOnIcon}
                sx={{ color: "text.secondary", mr: 2 }}
              />
            </Grid>
            <Grid item xs>
              <div>
                <RenderParsedText item={option.title} />
              </div>
              <Typography variant="body2" color="text.secondary">
                <RenderParsedText item={option.address} />
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};
export default NewPlaceSearch;
