import axios, { getData } from "./axios";
import * as tPromise from "io-ts-promise";
import { routeCargoRoadTollResponseT } from "dora-contracts";

export const performSmartmatchForRoute = async (route_id: number) => {
  await axios.post(`/api/perform-smartmatch-for-route/${route_id}`);
};

export const getEstimatedDistanceForCoords = async (
  coords: { lat: number; lon: number }[]
) => {
  return axios.post(`/api/get-estimated-distance-for-coords`, { coords });
};

export const getDanishRoadTollTaxForCargo = async (cargoId: string) => {
  return axios
    .get(`/api/cargos/${cargoId}/route-road-toll`)
    .then(getData)
    .then(tPromise.decode(routeCargoRoadTollResponseT));
};

export const regenerateRoutingWithRoadTollForCargo = async (cargoId: string) => {
  return axios.post(`/api/cargos/${cargoId}/route-road-toll`);
}
