import { ReactNode, createContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import * as actions from "./ducks/notifications";
import { getMatchings, getSmartMatches } from "./ducks/smart-match";
import { deleteSmartMatch } from "./ducks/smart-match";
import * as driverSessions from "./ducks/data/driver-sessions";
import * as cargoActions from "./ducks/data/cargos";
import * as routingActions from "./ducks/data/routing";
import * as cargoViewActions from "./ducks/data/cargo-views";
import * as routeActions from "./ducks/routes";
import * as routeViewModelActions from "./ducks/data/route-views";
import * as clientActions from "./ducks/data/clients";
import * as featureActions from "./ducks/features";
import * as customerContactActions from "./ducks/data/economics/customer-contacts";
import * as upcomingPageActions from "./ducks/app/upcoming-page";
import { useAppDispatch } from "./redux-store";
import { reloadRoute } from "./ducks/app/route-info/routing";
import { routeT } from "dora-contracts";
import * as t from "io-ts";
import { selectUser } from "./ducks/auth/selectors";
import { useSelector } from "react-redux";
import { routeInfoStopsT } from "dora-contracts";

export const SocketContext = createContext<Socket | null>(null);

const SocketConnection = ({ children }: { children?: ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useAppDispatch();
  const me = useSelector(selectUser);
  useEffect(() => {
    console.log("Establishing connection to server");
    const connection = io("/", {
      transports: ["websocket"],
      path: "/socket",
    });
    connection.onAny((evtName, evt) => {
      switch (evtName) {
        case "routing.created":
        case "routing.updated": {
          dispatch(routingActions.routingUpdated(evt.routeId));
          break;
        }
        case "features.updated": {
          dispatch(featureActions.initialize());
          break;
        }
        case "cargo.updated": {
          dispatch(cargoActions.reloadCargo(evt.cargoId));
          dispatch(upcomingPageActions.refresh());
          break;
        }
        case "cargo.assigned-to-route": {
          dispatch(routeActions.routeUpdated(decode(routeT, evt)));
          dispatch(upcomingPageActions.refresh());
          break;
        }
        case "cargo-view.deleted":
        case "cargo-view.updated": {
          dispatch(cargoViewActions.cargoViewUpdated(evt));
          break;
        }
        case "smartMatch.priceRequested":
          dispatch(
            actions.notifyL({
              namespace: "notifications",
              key: "smartMatchPriceRequested",
            })
          );
          // dispatch(smartMatchActions.addMatchings([evt]));
          //
          dispatch(getMatchings());
          break;
        case "smartMatch.priceGiven":
          dispatch(
            actions.notifyL({
              namespace: "notifications",
              key: "smartMatchPriceGiven",
            })
          );
          // TODO: Make this better, now we react to a socket message
          // by querying the backend :( We have all the data to correctly update the state.
          dispatch(getSmartMatches(evt.routeId));
          break;
        case "smartMatch.priceAccepted":
          dispatch(
            actions.notifyL({
              namespace: "notifications",
              key: "smartMatchPriceAccepted",
            })
          );
          dispatch(getMatchings());
          break;
        case "smartMatch.created":
          dispatch(getSmartMatches(evt.routeId));
          break;
        case "smartMatch.deleted":
          dispatch(
            deleteSmartMatch({
              matchId: evt.matchId,
              cargoInfoId: evt.routeId,
            })
          );
          break;
        case "driverSession.created":
          dispatch(driverSessions.sessionCreated(evt));
          break;
        case "driverSession.arrived":
        case "driverSession.departed":
        case "driverSession.stopCompleted":
          dispatch(driverSessions.sessionUpdated(evt));
          break;
        case "client.created":
          dispatch(clientActions.clientCreated(evt));
          break;
        case "client.updated":
          dispatch(clientActions.clientUpdated(evt));
          break;
        case "economic.customer-contact.created":
          dispatch(customerContactActions.contactCreated(evt));
          break;
        case "economic.customer-contact.updated":
          dispatch(customerContactActions.contactUpdated(evt));
          break;
        case "route.stop-order-updated":
          const payload = decode(
            t.strict({
              routeId: t.string,
              stops: routeInfoStopsT,
            }),
            evt
          );
          dispatch(
            routeActions.stopOrderUpdated({
              ...payload,
              stops: payload.stops.map((stop) => ({
                ...stop,
                stopId: (stop as any).stopId || stop.id,
              })),
            })
          );
          dispatch(reloadRoute(payload.routeId));
          break;
        case "route-view.updated":
          dispatch(routeViewModelActions.viewModelUpdated(evt));
          break;
      }
    });
    setSocket(connection);
    return () => {
      connection.close();
    };
  }, [me, dispatch]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

const decode = <T extends t.Any>(codec: T, input: unknown): t.TypeOf<T> => {
  const result: t.TypeOf<T> = codec.decode(input);
  switch (result._tag) {
    case "Left":
      throw new Error("Error decoding data");
    case "Right":
      return result.right;
  }
};

export default SocketConnection;
