import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNotifications,
  selectShowNetworkErrorMessage,
} from "../ducks/notifications/selectors";
import {
  dismissNotification,
  hideNetworkErrorMessage,
} from "../ducks/notifications";
import { useTranslation } from "react-i18next";
import { useFeature } from "../hooks";

const NetworkErrors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["notifications"]);
  const showNetworkErrors = useSelector(selectShowNetworkErrorMessage);
  return (
    <Snackbar open={showNetworkErrors}>
      <Alert
        severity="warning"
        onClose={() => {
          dispatch(hideNetworkErrorMessage());
        }}
      >
        {t("notifications:networkError")}
      </Alert>
    </Snackbar>
  );
};

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const closeNotification = (id: string) => dispatch(dismissNotification(id));
  const { t } = useTranslation();
  return (
    <>
      {notifications.map((n) => (
        <Snackbar open={true} key={n.id}>
          <Alert
            severity={n.type}
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => closeNotification(n.id)}
          >
            {n.message || t(n.key as any, { ns: n.namespace as any })}
          </Alert>
        </Snackbar>
      ))}
      <NetworkErrors />
    </>
  );
};

export default Notifications;
