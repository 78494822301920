import { useMemo, useState } from "react";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NotesIcon from "@mui/icons-material/Notes";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import axios from "../../../axios";
import { MapDistance } from "../map/map-distance.";
import { IconButton } from "../common/icon-button";
import { EditRouteNote } from "./edit-route-note";
import { useTranslation } from "react-i18next";
import * as actions from "../../../ducks/routes";
import { useAppDispatch } from "../../../redux-store";
import { useSelector } from "react-redux";
import TeamSelector from "../../TeamSelector";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";
import { RouteViewModelStop } from "dora-contracts";
import { useFeature } from "../../../hooks";
import LoadingListDownload from "./LoadingListDownload";
import { DoraCheckbox } from "../../Toolkit/DoraCheckbox";
import { selectRouting } from "../../../ducks/data/routing/selectors";
import {
  setRoutingAllowTravelByFerry,
  setRoutingFastestRoute,
} from "../../../ducks/data/routing";

const DashedLine = styled.div`
  border-left: 2px dashed rgba(0, 0, 0, 0.25);
  height: 14px;
  position: absolute;
  top: 24px;
  left: 12px;
`;

const DashedLineContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  width: 0px; // This works kinda like a min-width
`;

interface Props {
  routeId: string;
}

const formatStop = (input?: RouteViewModelStop | null) => {
  return input?.city || input?.postcode || "";
};

export const RouteDetails = (props: Props) => {
  const { routeId } = props;
  const { t } = useTranslation(["dispatching", "translation"]);
  const liteVersion = useFeature("lite-version");
  const route = useSelector(selectRouteViewModel(routeId));
  const routing = useSelector(selectRouting());
  const allowFerryTravel = useMemo(
    () => !!routing?.allowTravelByFerry,
    [routing]
  );
  // const fastestRoute = useMemo(() => !!routing?.fastestRoute, [routing]);
  const first_stop = useMemo(() => formatStop(route?.firstStop), [route]);
  const last_stop = useMemo(() => formatStop(route?.lastStop), [route]);
  const [isEditRouteNoteModalOpen, setIsEditRouteNoteModalOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const hidden = useMediaQuery((theme: any) => theme?.breakpoints?.down("lg"));
  const newDownloadButtons = useFeature("localized-load-unload-report");

  const onSetTeam = (teamId: string | null) => {
    teamId && dispatch(actions.assignToTeam({ routeId, teamId }));
  };

  const onAllowFerryTravelChange = () => {
    dispatch(
      setRoutingAllowTravelByFerry({
        routeId,
        allowTravelByFerry: !allowFerryTravel,
      })
    );
  };

  // const onSetFastestRoute = () => {
  //   dispatch(
  //     setRoutingFastestRoute({
  //       routeId,
  //       fastestRoute: !fastestRoute,
  //     })
  //   );
  // };

  const printRoutePdf = () => {
    axios
      .get("/api/loading-list/pdf", {
        params: { routeId: props.routeId },
        responseType: "blob",
      })
      .then((data) => {
        const file = new Blob([data.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  };

  return (
    (route && (
      <>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={{ marginTop: "24px" }}
        >
          {" "}
          {!liteVersion && (
            <Grid item xs>
              <Typography variant="h5" pb="16px">
                {t("dispatching:openRouteView.tabLabelInfo")} {route.ref || ""}
              </Typography>
            </Grid>
          )}
          {!liteVersion && (
            <Grid item xs={6} mb={2}>
              <TeamSelector value={route.assignedTeamId} onChange={onSetTeam} />
            </Grid>
          )}
          <Grid item xs={6} mb={3}>
            <div className="flex gap-2 items-center">
              <DoraCheckbox
                checked={allowFerryTravel}
                onChange={onAllowFerryTravelChange}
              />
              <label>{t("dispatching:openRouteView.allowTravelByFerry")}</label>
            </div>
            {/*<div className="flex gap-2 items-center mt-2">*/}
            {/*  <DoraCheckbox*/}
            {/*    checked={fastestRoute}*/}
            {/*    onChange={onSetFastestRoute}*/}
            {/*  />*/}
            {/*  <label>{t("dispatching:openRouteView.fastestRoute")}</label>*/}
            {/*</div>*/}
          </Grid>
          {/* Reintroduce route ref later */}
          {/* <Typography variant="h6" color="textSecondary">
                    XD234-SD
                </Typography> */}
        </Grid>

        <Grid
          container
          wrap="nowrap"
          direction="row"
          justifyContent="space-between"
        >
          {hidden || !first_stop ? null : (
            <DashedLineContainer>
              <DashedLine />
              <IconButton
                icon={<ArrowCircleUpOutlinedIcon color="disabled" />}
                text={first_stop || ""}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <IconButton
                icon={<LocationOnOutlinedIcon color="disabled" />}
                text={last_stop || ""}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            </DashedLineContainer>
          )}
          <Grid
            sx={{
              position: "relative",
              overflow: "hidden",
              flex: 1,
              width: "0px",
            }}
            pl={1}
            item
          >
            <Grid item>
              {!liteVersion && (
                <IconButton
                  onClick={() => setIsEditRouteNoteModalOpen(true)}
                  icon={<NotesIcon color="disabled" />}
                  text={t("dispatching:openRouteView.actions.viewNote")}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {newDownloadButtons ? (
                <LoadingListDownload routeId={props.routeId} />
              ) : (
                <IconButton
                  onClick={printRoutePdf}
                  icon={<DownloadIcon color="disabled" />}
                  text={t(
                    "dispatching:openRouteView.actions.loadingUnloadingList"
                  )}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Grid pl={1} item>
            <MapDistance />
          </Grid>

          {/* Might be used later */}
          {/* <Grid item>
                    <Grid container>
                        <IconButton
                            icon={<StarHalfIcon color="disabled" />}
                            text="Kapacitetsudnyttelse"
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Typography variant="h3">66%</Typography>
                    </Grid>
                </Grid> */}
        </Grid>

        <EditRouteNote
          routeId={props.routeId}
          isOpen={isEditRouteNoteModalOpen}
          onClose={() => setIsEditRouteNoteModalOpen(false)}
        />
      </>
    )) ||
    null
  );
};
