import { CloseButton } from "../../dispatching-planning/common/close-button";
import { Button } from "@mui/material";
import SavedLocationRow from "./SavedLocationRow";
import SavedLocation, { SavedLocationType } from "./SavedLocation";
import { useEffect, useState } from "react";
import SavedLocationTypeSelectionOption from "./SavedLocationTypeSelectionOption";
import { useAppDispatch, useSelector } from "../../../redux-store";
import {
  selectFavoriteLocations,
  selectTerminalLocations,
} from "../../../ducks/data/saved-locations/selectors";
import { loadSavedLocations } from "../../../ducks/data/saved-locations";
import { useTranslation } from "react-i18next";
import { DoraTextInput } from "../../Toolkit/DoraTextInput";

const ManageFavoritesAndTerminals = ({
  onAddClick,
  onEditClick,
  onTypeChange,
  type,
  onClose,
}: {
  onAddClick: () => void;
  onEditClick: (location: SavedLocation) => void;
  onTypeChange: (type: SavedLocationType) => void;
  type: SavedLocationType;
  onClose: () => void;
}) => {
  const { t } = useTranslation(["modals", "components"]);

  const [selectionType, setSelectionType] = useState<SavedLocationType>(type);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const terminals = useSelector(selectTerminalLocations);
  const favorites = useSelector(selectFavoriteLocations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadSavedLocations());
  }, [dispatch]);

  const onCategoryClick = (type: SavedLocationType) => {
    setPage(1);
    setSelectionType(type);
    onTypeChange(type);
    dispatch(loadSavedLocations());
  };

  const getLocations = () => {
    let locations = selectionType === "TERMINAL" ? terminals : favorites;
    if (search) {
      locations = locations.filter((location) =>
        search
          ? location.place.city?.toLowerCase().includes(search) ||
            location.place.country?.toLowerCase().includes(search) ||
            location.place.postcode?.toLowerCase().includes(search) ||
            location.place.address?.toLowerCase().includes(search) ||
            location.place.placeName?.toLowerCase().includes(search) ||
            location.customName.includes(search.toLowerCase())
          : true
      );
    }
    return locations.slice((page - 1) * 7, page * 7);
  };

  const onSearchChange = (search: string) => {
    setSearch(search.toLowerCase());
    console.log(search);
  };

  const totalPages = Math.ceil(
    (selectionType === "TERMINAL" ? terminals : favorites).length / 7
  );

  return (
    <div className="favorites-terminals-dialog__container">
      <div className="favorites-terminals-dialog__title">
        <div>{t("modals:manageSavedLocations.manageTitle")}</div>
        <CloseButton onClick={onClose} />
      </div>
      <div className="favorites-terminals-dialog__subtitle">
        {t("modals:manageSavedLocations.description")}
      </div>
      <div className="favorites-terminals-dialog__first-row">
        <div style={{ display: "flex" }} role="radiogroup">
          <SavedLocationTypeSelectionOption
            type={"favorite"}
            active={selectionType === "FAVORITE"}
            onClick={() => onCategoryClick("FAVORITE")}
          />
          <SavedLocationTypeSelectionOption
            type={"terminal"}
            active={selectionType === "TERMINAL"}
            onClick={() => onCategoryClick("TERMINAL")}
          />
        </div>
        <Button variant="contained" onClick={onAddClick}>
          + {t("modals:manageSavedLocations.addButton")}
        </Button>
      </div>
      <div className="favorites-terminals-dialog__location-text">
        {t("modals:manageSavedLocations.locations")}
      </div>

      <div className="px-6 py-2">
        <DoraTextInput
          onChange={onSearchChange}
          placeholder={t("components:buttonLabels.search")}
        ></DoraTextInput>
      </div>

      {getLocations().map((location, i) => (
        <div key={i}>
          <hr className="favorites-terminals-dialog__hr" />
          <SavedLocationRow
            location={location}
            onEditClick={() => onEditClick(location)}
          ></SavedLocationRow>
        </div>
      ))}
      <hr className="favorites-terminals-dialog__hr" />
      <div className="favorites-terminals-dialog__bottom-row">
        <div>
          {t("modals:manageSavedLocations.pagination", {
            currentPage: page,
            totalPages,
          })}
        </div>
        <div className="favorites-terminals-dialog__pagination-buttons">
          <Button
            variant="outlined"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            {t("modals:manageSavedLocations.previousButton")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages || totalPages === 0}
          >
            {t("modals:manageSavedLocations.nextButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageFavoritesAndTerminals;
