import axios from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { sessionT, responseT, Session } from "./types";
import * as tPromise from "io-ts-promise";
import groupBy from "lodash/groupBy";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { DateTime } from "luxon";

const prefix = "data/driver-sessions";

export interface CompletedStop {
  stopId: string;
  completedAt: DateTime;
  driverId: string;
}

export interface ArrivedStop {
  stopId: string;
  arrivedAt: DateTime;
  driverId: string;
}

export interface DepartedStop {
  stopId: string;
  departedAt: DateTime;
  driverId: string;
}

type State = {
  byRouteId: Record<string, Session[]>;
  completedStops: Record<string, CompletedStop>;
  arrivedStops: Record<string, ArrivedStop>;
  departedStops: Record<string, DepartedStop>;
};

const initialState: State = {
  byRouteId: {},
  completedStops: {},
  arrivedStops: {},
  departedStops: {},
};

export const loadSessions = createErrorReportingAsyncThunk(
  `${prefix}/load-sessions`,
  async () => {
    const { data } = await axios.get("/api/driver-sessions");
    const decode = tPromise.decode(responseT);
    return await decode(data);
  }
);

/**
 * Called when a web-socket message is received
 */
export const sessionCreated = createErrorReportingAsyncThunk(
  `${prefix}/session-created`,
  async (event: unknown) => {
    return tPromise.decode(sessionT, event);
  }
);

export const sessionUpdated = createErrorReportingAsyncThunk(
  `${prefix}/session-updated`,
  async (event: unknown) => {
    return tPromise.decode(sessionT, event);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSessions.fulfilled, (state, action) => {
        state.byRouteId = groupBy(action.payload.sessions, "routeId");
        for (const session of action.payload.sessions) {
          for (const stop of session.completedStops) {
            const { stopId, completedAt } = stop;
            state.completedStops[stopId] = {
              stopId,
              completedAt: completedAt as DateTime, // TODO: fix this (WritableDraft...)
              driverId: session.driverId,
            };
          }
          for (const stop of session.arrivedStops) {
            const { stopId, arrivedAt } = stop;
            state.arrivedStops[stopId] = {
              stopId,
              arrivedAt: arrivedAt as DateTime, // TODO: fix this (WritableDraft...)
              driverId: session.driverId,
            };
          }
          for (const stop of session.departedStops) {
            const { stopId, departedAt } = stop;
            state.departedStops[stopId] = {
              stopId,
              departedAt: departedAt as DateTime, // TODO: fix this (WritableDraft...)
              driverId: session.driverId,
            };
          }
        }
      })
      .addCase(sessionCreated.fulfilled, (state, action) => {
        const session = action.payload;
        const current = state.byRouteId[session.routeId] || [];
        if (current.some((x) => x.id === session.id)) {
          return;
        }
        state.byRouteId[session.routeId] = [...current, session];
      })
      .addCase(sessionUpdated.fulfilled, (state, action) => {
        const session = action.payload;
        for (const stop of action.payload.completedStops) {
          const { stopId, completedAt } = stop;
          state.completedStops[stopId] = {
            stopId,
            completedAt: completedAt as DateTime, // TODO: fix this (WritableDraft...)
            driverId: session.driverId,
          };
        }
        for (const stop of action.payload.arrivedStops) {
          const { stopId, arrivedAt } = stop;
          state.arrivedStops[stopId] = {
            stopId,
            arrivedAt: arrivedAt as DateTime, // TODO: fix this (WritableDraft...)
            driverId: session.driverId,
          };
        }
        for (const stop of action.payload.departedStops) {
          const { stopId, departedAt } = stop;
          state.departedStops[stopId] = {
            stopId,
            departedAt: departedAt as DateTime, // TODO: fix this (WritableDraft...)
            driverId: session.driverId,
          };
        }
      });
  },
});

export default slice.reducer;
