import { useL10n } from "../../../../l10n";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Decimal from "decimal.js-light";
import {
  getDanishRoadTollTaxForCargo,
  regenerateRoutingWithRoadTollForCargo,
} from "../../../../api";
import DoraButton from "../../../Toolkit/DoraButton";
import { useAppDispatch } from "../../../../redux-store";
import { notifyL } from "../../../../ducks/notifications";
import RefreshIcon from "@mui/icons-material/Refresh";
import { MuiTooltip } from "../../../nav/nav-styles";

const RouteRoadToll = ({ cargoId }: { cargoId: string | null }) => {
  const { t } = useTranslation(["shipmentModal"]);
  const l10n = useL10n();

  const [cargoToll, setCargoToll] = useState<Decimal>(new Decimal(0));
  const [routeToll, setRouteToll] = useState<Decimal | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isOnRoute, setIsOnRoute] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cargoId) {
      loadRoadToll(cargoId);
    }
  }, [cargoId]);

  const loadRoadToll = async (cId: string) => {
    const data = await getDanishRoadTollTaxForCargo(cId);
    if (data.cargoToll === null) {
      setCargoToll(new Decimal(0));
    } else {
      setCargoToll(new Decimal(data.cargoToll));
    }
    if (data.routeToll) {
      setRouteToll(new Decimal(data.routeToll));
    }
    setIsOnRoute(data.isOnRoute);
    setIsCalculating(data.isCalculating);
  };

  if (!cargoId || !isOnRoute) {
    return null;
  }

  const onCalculateButtonClick = async () => {
    setIsCalculating(true);
    try {
      await regenerateRoutingWithRoadTollForCargo(cargoId);
    } catch (err) {
      dispatch(
        notifyL({
          type: "error",
          namespace: "notifications",
          key: "calculateRoadTollFailed",
        })
      );
      throw err;
    }
  };

  const onRefreshClick = async () => {
    setIsRefreshing(true);
    await Promise.all([
      loadRoadToll(cargoId),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ]);
    setIsRefreshing(false);
  };

  return isRefreshing ? (
    <div className="flex items-center">
      {t("shipmentModal:routeRoadTollLoading")}
    </div>
  ) : (
    <div className="flex gap-2 items-center">
      {isCalculating ? (
        <>
          <div style={{ fontSize: 12 }}>
            {t("shipmentModal:routeRoadTollWaitOrPress")}
          </div>
          <MuiTooltip title="Reload" placement="top">
            <DoraButton
              variant={"primary"}
              style={{ padding: "4px 7px" }}
              onClick={onRefreshClick}
            >
              <RefreshIcon style={{ fontSize: 20 }} />
            </DoraButton>
          </MuiTooltip>
        </>
      ) : null}
      <DoraButton
        variant="primary"
        onClick={onCalculateButtonClick}
        disabled={isCalculating}
      >
        {isCalculating
          ? t("shipmentModal:routeRoadTollCalculating")
          : t("shipmentModal:routeRoadTollCalculate")}
      </DoraButton>
      <div className="shipment-dialog__shipment-stat">
        <div>{t("shipmentModal:routeRoadToll")}</div>
        <div>
          {l10n.formatNumber(cargoToll, 2)} DKK{" "}
          {routeToll ? `(${l10n.formatNumber(routeToll, 2)} DKK)` : null}
        </div>
      </div>
    </div>
  );
};

export default RouteRoadToll;
