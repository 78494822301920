import axios from "../../../axios";
import * as tPromise from "io-ts-promise";
import { createErrorReportingAsyncThunk } from "../../helpers";
import {
  DriverActivityResponse,
  driverActivityResponseT,
} from "dora-contracts";
import { createSlice } from "@reduxjs/toolkit";
import { sessionCreated, sessionUpdated } from "../driver-sessions";

const prefix = "data/driver-activities";

type State = {
  byRouteId: DriverActivityResponse["logins"];
  completedStops: Record<string, DriverActivityResponse["stopsCompleted"]["0"]>;
  arrivedStops: Record<string, DriverActivityResponse["stopsArrived"]["0"]>;
  departedStops: Record<string, DriverActivityResponse["stopsDeparted"]["0"]>;
};

const initialState: State = {
  byRouteId: {},
  completedStops: {},
  arrivedStops: {},
  departedStops: {},
}

export const loadDriversActivities = createErrorReportingAsyncThunk(
  `${prefix}/load-drivers-activities`,
  async () => {
    const { data } = await axios.get("/api/driver-activities");
    const decode = tPromise.decode(driverActivityResponseT);
    return await decode(data);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadDriversActivities.fulfilled, (state, action) => {
        state.byRouteId = action.payload.logins;
        for (const stop of action.payload.stopsCompleted) {
          state.completedStops[stop.stopId] = stop;
        }
        for (const stop of action.payload.stopsArrived) {
          state.arrivedStops[stop.stopId] = stop;
        }
        for (const stop of action.payload.stopsDeparted) {
          state.departedStops[stop.stopId] = stop;
        }

        state.completedStops = action.payload.stopsCompleted.reduce((acc, stop) => {
          acc[stop.stopId] = stop;
          return acc;
        }, {} as State["completedStops"]);
        state.arrivedStops = action.payload.stopsArrived.reduce((acc, stop) => {
          acc[stop.stopId] = stop;
          return acc;
        }, {} as State["arrivedStops"]);
        state.departedStops = action.payload.stopsDeparted.reduce((acc, stop) => {
          acc[stop.stopId] = stop;
          return acc;
        }, {} as State["departedStops"]);
      })
      .addCase(sessionCreated.fulfilled, (state, action) => {
        const session = action.payload;
        const current = state.byRouteId[session.routeId] || [];
        if (current.loggedInAt < session.loggedInAt) {
          state.byRouteId[session.routeId] = {
            driverId: session.driverId,
            trailerId: session.trailerId!, // TODO: mandatory?
            loggedInAt: session.loggedInAt,
          };
        }
      })
      .addCase(sessionUpdated.fulfilled, (state, action) => {
        const session = action.payload;
        for (const stop of action.payload.completedStops) {
          const { stopId, completedAt } = stop;
          state.completedStops[stopId] = {
            stopId,
            completedAt,
            driverId: session.driverId,
          };
        }
        for (const stop of action.payload.arrivedStops) {
          const { stopId, arrivedAt } = stop;
          state.arrivedStops[stopId] = {
            stopId,
            arrivedAt,
            driverId: session.driverId,
          };
        }
        for (const stop of action.payload.departedStops) {
          const { stopId, departedAt } = stop;
          state.departedStops[stopId] = {
            stopId,
            departedAt,
            driverId: session.driverId,
          };
        }
      });
  },
});

export default slice.reducer;
