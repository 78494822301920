import axios, { getData } from "../../../axios";
import * as t from "io-ts";
import * as types from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as tPromise from "io-ts-promise";
import { getClientsResponseT, createDeferredInvoiceT } from "./types";

const prefix = "/data/consolidated-invoicing";

type State = {
  uninvoicedClientsInfo: types.UninvoicedClients;
  data: null | {
    invoices: types.DeferredInvoice[];
  };
};

const initialState: State = {
  uninvoicedClientsInfo: [],
  data: null,
};

export const loadUninvoicedClientsInfo = createErrorReportingAsyncThunk(
  `${prefix}/load-clients`,
  async () => {
    return axios
      .get(`/api/consolidated-invoicing/clients`)
      .then(getData)
      .then(tPromise.decode(getClientsResponseT));
  }
);

const deferredInvoicesT = t.array(types.deferredInvoiceT);

export const loadForClient = createErrorReportingAsyncThunk(
  `${prefix}/load-for-client`,
  async (clientId: string) => {
    const invoices = await axios
      .get(`/api/consolidated-invoicing/unsent-invoices?client=${clientId}`)
      .then(getData)
      .then(tPromise.decode(deferredInvoicesT));
    return { invoices };
  }
);

export const createInvoice = createErrorReportingAsyncThunk(
  `${prefix}/send-invoice`,
  async (input: {
    clientId: string;
    customerContactId: string | null;
    invoiceRef: string;
    invoiceIds: string[];
    groupProducts: boolean;
  }) => {
    await axios.post(
      "/api/consolidated-invoicing/invoices",
      createDeferredInvoiceT.encode(input)
    );
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadForClient.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        const invoicedInvoices = action.meta.arg.invoiceIds;
        if (state.data) {
          state.data.invoices = state.data.invoices.filter(
            (invoice) => !invoicedInvoices.includes(invoice.id)
          );
        }
      })
      .addCase(loadUninvoicedClientsInfo.fulfilled, (state, action) => {
        state.uninvoicedClientsInfo = action.payload;
      });
  },
});

export default slice.reducer;
