import * as t from "io-ts";
import { createDeliveryTypes } from "dora-contracts";

export const {
  lineT,
  expenseT,
  deliveryT,
  deliveryUpdateResponseT,
  createUpdateExpenseT,
  createUpdateInvoiceLineT,
  sendInvoiceRequestT,
  sendInvoiceTypeT,
  updateSurchargeLineT,
  updateInvoiceLineOrderNumberT
} = createDeliveryTypes({
  decimalT: t.string,
});

export type Expense = t.TypeOf<typeof expenseT>;
export type CreateUpdateExpense = t.TypeOf<typeof createUpdateExpenseT>;
export type Delivery = t.TypeOf<typeof deliveryT>;
export type Line = t.TypeOf<typeof lineT>;
export type CreateUpdateInvoiceLine = t.TypeOf<typeof createUpdateInvoiceLineT>;
export type SendInvoiceType = t.TypeOf<typeof sendInvoiceTypeT>;