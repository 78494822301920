import { DateTime } from "luxon";
import { CargoFormData, stopT } from "./types";
import { v4 as uuid } from "uuid";
import { NewShipmentDateOffset } from "../../data/org-settings/types";
import { CargoFromApi } from "./index";
import * as t from "io-ts";
import { CargoFormDataBase, Stop } from "../cargo-and-template-shared";

export const getStopDate = (daysAdjustment: NewShipmentDateOffset | null) =>
  DateTime.now()
    .plus({ day: parseInt(daysAdjustment || "1") })
    .toFormat("yyyy-MM-dd");

const defaultLoadmeters = 13.6;

export const emptyStop = (
  newShipmentDateOffset: NewShipmentDateOffset | null
): Stop => ({
  flipKey: uuid(),
  loadmeters: 0,
  modified: false,
  date: getStopDate(newShipmentDateOffset),
  openingDate: getStopDate(newShipmentDateOffset),
  openingTime: null,
  fix: false,
  place: null,
  weight: "",
  colli: "",
  length: "",
  height: "",
  width: "",
  cubicMeters: "",
  temperature: "",
  time: "",
  ref: "",
  tag: "",
  contact: "",
  contactPhone: "",
  pieces: [],
});

export const defaultFormData = ({
  assignedTeamId,
  newShipmentDateAdjustment,
}: {
  assignedTeamId: string;
  newShipmentDateAdjustment: NewShipmentDateOffset | null;
}): CargoFormDataBase => {
  return {
    clientContact: "",
    clientContactEmail: "",
    clientContactPhone: "",
    driverCanAddDropoffs: false,
    driverCanRegisterTime: false,
    invoiceRef: "",
    cargoTagId: null,
    smartMatch: false,
    customerContactId: null,
    description: "",
    qualities: [],
    requirements: [],
    type: "MISCELLANEOUS",
    note: "",
    assignedTeamId,
    minTemperature: "",
    maxTemperature: "",
    containerNo: "",
    adr: false,
    adrText: "",
    pickupList: [
      {
        ...emptyStop(newShipmentDateAdjustment),
        loadmeters: defaultLoadmeters,
        modified: false,
      },
    ],
    dropoffList: [
      {
        ...emptyStop(newShipmentDateAdjustment),
        loadmeters: defaultLoadmeters,
        modified: false,
      },
    ],
    extraCo2Elements: [],
  };
};

type ApiStop = t.TypeOf<typeof stopT>;

export const apiStopToFormStop = (x: ApiStop): Stop => ({
  ...x,
  flipKey: uuid(),
  modified: true,
  loadmeters: +x.loadmeters,
  place: x.place && {
    placeName: x.place.placeName || "",
    address: x.place.address || "",
    postcode: x.place.postcode || "",
    city: x.place.city || "",
    country: x.place.country || "",
    coord: x.place.coord,
  },
  date: x.date || "",
  openingDate: x.openingDate || "",
  openingTime: x.openingTime || "",
  weight: x.weight || "",
  colli: x.colli || "",
  length: x.length || "",
  height: x.height || "",
  width: x.width || "",
  cubicMeters: x.cubicMeters || "",
  temperature: x.temperature || "",
  time: x.time || "",
  ref: x.ref || "",
  tag: x.tag || "",
  contact: x.contact || "",
  contactPhone: x.contactPhone || "",
  pieces: x.pieces,
});

export const apiCargoToFormData = (data: CargoFromApi): CargoFormData => ({
  ...data,
  clientId: data.clientId || undefined,
  customerContactId: data.customerContactId || null,
  invoiceRef: data.invoiceRef || null,
  description: data.description || "",
  qualities: data.qualities || [],
  requirements: data.requirements || [],
  clientContactEmail: data.clientContactEmail || "",
  clientContactPhone: data.clientContactPhone || "",
  clientContact: data.clientContact || "",
  minTemperature: data.minTemperature || "",
  maxTemperature: data.maxTemperature || "",
  containerNo: data.containerNo || "",
  pickupList: data.pickupList.map(apiStopToFormStop),
  dropoffList: data.dropoffList.map(apiStopToFormStop),
  note: data.note || "",
});

export const formDataStopToApiStop = (stop: Stop): ApiStop => {
  return {
    ...stop,
    loadmeters: stop.loadmeters.toString(),
  };
};
